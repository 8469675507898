<template>
	<!-- 팝업 -->
	<div :class="imagePopLayer" id="layer01">
		<div class="inner2">
			<a href="javascript:void(0)" name="layer01" @click="onCloseImage">닫기23</a>
		</div>
		<swiper ref="mySwiper" class="swiper" :options="swiperOption">
			<swiper-slide v-for="(item, index) in imageList" :key="index">
				<div class="swiper-zoom-container">
					<img :src="`/file/${item.fileId}`" />
				</div>
			</swiper-slide>
			<div class="swiper-button-prev" slot="button-prev"></div>
			<div class="swiper-button-next" slot="button-next"></div>
		</swiper>
	</div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
	name: 'slider',
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			imagePopLayer: 'layerPop photoPop',
			imageId: null,
			imageList: [],
			swiperOption: {
				loop: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			},
		};
	},
	computed: {
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
	},
	methods: {
		async onOpenImage(imageId, images) {
			let number = 0;

			for (let value in images) {
				if (images[value].fileId == imageId) {
					number = parseInt(value);
				}
			}

			this.imageList = images;
			this.imageId = imageId;
			this.imagePopLayer = 'layerPop photoPop on';

			this.swiper.slideTo(number, 0, false);
		},
		onCloseImage() {
			this.imagePopLayer = 'layerPop photoPop';
			this.imageId = null;
			this.imageList = null;
		},
	},
};
</script>
<style scoped>
.layerPop.photoPop .inner .cont {
	display: grid;
}
</style>
